import { combineReducers } from 'redux-immutable';
import { reducer as formReducer } from 'redux-form/immutable';

import auth from './components/auth/reducer';
import restorePassword from './components/auth/RestorePassword/reducer';
import newPasswordReducer from './components/auth/SetNewPassword/store/reducer';
import userMenu from './components/auth/UserDropdown/reducer';
import createProjectMenu from './components/Projects/MyProjectsPage/Components/ProjectCreateDropdown/reducer';
import changePassword from './components/auth/ChangePassword/reducer';
import activateCreatedUserReducer from './components/auth/ActivateCreatedUser/store/reducer';
import twoFactorAuthenticationReducer from './components/auth/TwoFactorAuthentication/store/reducer';
import headerReducer from './components/Header/reducer';
import availableSets from './components/Sets/SetsList/reducer';
import myProjects from './components/Projects/MyProjectsPage/reducer';
import setsManagement from './components/Sets/SetsManagement/reducer';
import taxonomies from './components/Sets/Taxonomies/reducer';
import findRelatedAnalysis from './components/Analytics/FindRelated/FindRelatedResult/reducer';
import findRelatedProteinOrRegulationAnalysis from './components/Analytics/FindRelated/FindRelatedProteinOrRegulationResult/reducer';
import analysisResultRanking from './components/Analytics/RankSelection/SetAnalysisRankingResult/reducer';
import analysisResultNetwork from './components/Analytics/NetworkAnalysis/SetAnalysisNetworkAnalysisResult/reducer';
import searchResult from './components/Search/store/reducer';
import searchRelatedConceptsReducer from './components/Search/Components/SearchRelatedConceptsCard/store/reducer';
import conceptDetails from './components/Concept/ConceptDetails/reducer';
import publicationDetails from './components/PublicationDetails/reducer';
import barChart from './components/graphics/BarChart/store/reducer';
import relationMapManagement from './components/RelationMap/RelationMapManagement/store/reducer';
import setResultPage from './components/SetResult/SetResultPage/reducer';
import setResultPageAddConcepts from './components/SetResult/SetResultPageAddConcepts/reducer';
import setAnalysisManagement from './components/Analytics/SetAnalysisManagement/reducer';
import userActivation from './components/auth/UserActivation/reducer';
import geneDisease from './components/GeneDisease/GeneDiseasePage/reducer';
import geneDiseaseAnnotation from './components/GeneDisease/GeneDiseaseAnnotationSection/reducer';
import geneDiseaseDifferentialExpressionSection from './components/GeneDisease/GeneDiseaseDifferentialExpressionSection/reducer';
import geneDiseaseGeneMutation from './components/GeneDisease/GeneDiseaseGeneMutationSection/reducer';
import geneDiseaseAnimalModel from './components/GeneDisease/GeneDiseaseAnimalModelSection/reducer';
import geneDiseaseMetabolites from './components/GeneDisease/GeneDiseaseMetabolitesSection/reducer';
import geneDiseasePhenotype from './components/GeneDisease/GeneDiseasePhenotypeSection/reducer';
import geneDiseasePathway from './components/GeneDisease/GeneDiseasePathwaySection/reducer';
import geneDiseaseCellMolecular from './components/GeneDisease/GeneDiseaseCellMollecularDysfunction/reducer';
import geneDiseaseOrganTissue from './components/GeneDisease/GeneDiseaseOrganTissueFunction/reducer';
import geneDiseaseConclusion from './components/GeneDisease/GeneDiseaseConclusion/reducer';
import pathDetails from './components/Analytics/NetworkAnalysis/PathDetails/reducer';
import complexSetView from './components/Sets/ComplexSetView/reducer';
import pathDetailsSmallMolecules from './components/Analytics/NetworkAnalysis/PathDetails/SmallMoleculesSection/reducer';
import pubDetailsTriples from './components/PublicationDetails/Components/PublicationDetailsTriples/reducer';
import locationReducer from './location/locationReducer';
import shortConceptCardReducer from './components/Concept/ShortConceptCard/reducer';
import searchCategoryTabsReducer from './components/Search/Components/SearchCategoryTab/store/reducer';
import conceptCardsReducer from './components/Concept/ConceptCards/store/reducer';
import createSetReducer from './components/Sets/CreateSet/store/reducer';
import publicationsFilterGraphReducer from './components/graphics/PublicationsFilterGraph/store/reducer';
import setsVennReducer from './components/Sets/SetVennDiagram/reducer';
import setsDataSelectionReducer from './components/Sets/SetsDataSelection/reducer';
import heatMapReducer from './components/graphics/Heatmap/store/reducer';
import compareSetsReducer from './components/Sets/CompareSetsPage/store/reducer';
import buildHeatmapReducer from './components/Sets/BuildHeatmap/store/reducer';
import setAnalysis from './components/Analytics/common/SetAnalysis/reducer';
import setAnalysisMethodSelection from './components/Analytics/common/SetAnalysisMethodSelection/reducer';
import projectsManagementReducer from './components/Projects/ProjectsManagement/store/reducer';
import projectsTabsReducer from './components/Projects/ProjectsTabs/store/reducer';
import versionDialogReducer from './components/Modals/VersionModal/store/reducer';
import publicationsManagementReducer from './components/Publications/PublicationsManagement/store/reducer';
// Gene Details
import geneDetails from './components/GeneDetails/reducer';
import geneDetailsGeneExpressionChapter from './components/GeneDetails/GeneExpressionChapter/reducer';
import samplesReducer from './components/GeneDetails/GeneExpressionChapter/Charts/SamplesChart/store/reducer';
import cellTypeClusterModalReducer from './components/GeneDetails/GeneExpressionChapter/Charts/HpaCharts/components/CellTypeClusterModal/store/reducer';
import geneDetailsProteinLocationChapter from './components/GeneDetails/ProteinLocationChapter/reducer';
import geneDetailsFunctionalCharacterizationChapter from './components/GeneDetails/FunctionalCharacterizationChapter/redux/reducer';
import geneDetailsPathwaysChapter from './components/GeneDetails/PathwaysChapter/store/reducer';
import geneDetailsSimilarProteinsPageChapter from './components/GeneDetails/SimilarProteinsChapter/redux/reducer';
import geneDetailsOrthologsChapterReducer from './components/GeneDetails/OrthologsChapter/store/reducer';
import geneDetailsPhenotypesChapter from './components/GeneDetails/PhenotypesChapter/redux/reducer';
import geneDetailsProteinStructureChapter from './components/GeneDetails/ProteinStructure/redux/reducer';
import geneDetailsConditionOfDysregulationChapter from './components/GeneDetails/ConditionsOfDysregulationChapter/redux/reducer';
import geneDetailsTherapeuticCandidatesChapter from './components/GeneDetails/TherapeuticCandidatesChapter/redux/reducer';
import geneDetailsPatentsChapter from './components/GeneDetails/Patents/redux/reducer';
import geneDetailsClinicalTrialsChapter from './components/GeneDetails/ClinicalTrialsChapter/redux/reducer';
import geneDetailsDisordersChapter from './components/GeneDetails/DisordersChapter/redux/reducer';
import geneDetailsExternalLinksChapterReducer from './components/GeneDetails/ExternalLinksChapter/store/reducer';
import geneDetailsAntibodiesChapterReducer from './components/GeneDetails/AntibodiesChapter/store/reducer';
import literatureSearchReducer from './components/GeneDetails/LiteratureSearchChapter/reducer';
import geneDetailsInternalLinksChapterReducer from './components/GeneDetails/InternalLinksChapter/store/reducer';
// Search
import searchConceptPopupReducer from './components/Concept/ConceptSearchModal/store/reducer';
// Relation Map
import relationMapProject from './components/RelationMap/RelationMapPage/store/reducer';
import hideSourceModalReducer from './components/RelationMap/RelationMapPage/Components/HideSourceModal/store/reducer';
import hideRelationTypesModalReducer from './components/RelationMap/RelationMapPage/Components/HideRelationTypesModal/store/reducer';
import intermediateConceptsReducer from './components/RelationMap/RelationMapPage/Components/IntermediateConceptsModal/store/reducer';
// Common Components
import addTag from './components/common/AddTag/store/reducer';
import addConcept from './components/common/AddConcept/reducer';
import tooltipReducer from './components/common/Tooltip/store/reducer';
import setAnalysisGenesDetail from './components/common/SetAnalysisGenesDetail/reducer';
import publicationsListReducer from './components/common/PublicationsList/store/reducer';
import freeAccountLimitDialogReducer from './components/common/FreeAccountLimitationDialog/store/reducer';
import selectGene from './components/common/SelectGene/store/reducer';
import selectDisease from './components/common/SelectDisease/store/reducer';
// Sets
import saveAsSetReducer from './components/Sets/SaveAsSet/store/reducer';
import unresolvedConceptsReducer from './components/Sets/SaveAsSet/Components/UnresolvedConceptsTable/store/reducer';
import searchWithSetsPageReducer from './components/Sets/SearchWithSetsPage/store/reducer';
import editSetReducer from './components/Sets/EditSet/store/reducer';
import findRelatedReducer from './components/FindRelated/store/reducer';
import enrichmentAnalysisParamsReducer from './components/FindRelated/Components/EnrichmentAnalysisParams/store/reducer';
import rankSelectionReducer from './components/RankSelection/store/reducer';
import rankSelectionResultReducer from './components/RankSelection/Components/RankSelectionResult/reducer';
import backgroundSet from './components/common/SelectBackgroundSetComponent/store/reducer';
// Workflows
import workflowsReducer from './components/Workflows/store/reducer';
import bispecificAntibodyReducer from './components/Workflows/Algorithms/BispecificAntibody/store/reducer';
import singleTargetForCancerReducer from './components/Workflows/Algorithms/SingleTargetForCancer/reducer';
import singleCellStudyReducer from './components/Workflows/Algorithms/SingleCellStudyViewer/store/reducer';
import datasetsOptionsReducer from './components/Workflows/Algorithms/SingleCellStudyViewer/Components/DatasetsSelector/store/reducer';
import cellLineSelectionReducer from './components/Workflows/Algorithms/CellLineSelection/store/reducer';
// Indication Diseases Finder
import indicationDiseasesFinderReducer from './components/Workflows/Algorithms/IndicationFinder/Components/ResultDiseaseBlock/store/reducer';
// Upload
import uploadFileReducer from './components/common/UploadFile/store/reducer';
//Association score
import c2cIndirectReducer from './components/common/ConceptToConceptIndirectChart/store/reducer';
//Antibodies page
import antibodiesReducer from './components/AntibodiesPage/store/reducer';

import toastReducer from './toast/toastReducer';

function createMainReducer() {
  return combineReducers({
    locationReducer,
    toastReducer,
    auth,
    restorePassword,
    newPasswordReducer,
    changePassword,
    activateCreatedUserReducer,
    twoFactorAuthenticationReducer,
    headerReducer,
    availableSets,
    myProjects,
    setsManagement,
    form: formReducer,
    taxonomies,
    setAnalysis,
    setAnalysisMethodSelection,
    findRelatedAnalysis,
    findRelatedProteinOrRegulationAnalysis,
    analysisResultRanking,
    analysisResultNetwork,
    searchResult,
    searchRelatedConceptsReducer,
    conceptDetails,
    publicationDetails,
    userMenu,
    createProjectMenu,
    barChart,
    setResultPage,
    setResultPageAddConcepts,
    setAnalysisManagement,
    userActivation,
    geneDisease,
    geneDiseaseAnnotation,
    geneDiseaseDifferentialExpressionSection,
    geneDiseaseGeneMutation,
    geneDiseaseAnimalModel,
    geneDiseaseMetabolites,
    geneDiseasePhenotype,
    geneDiseasePathway,
    geneDiseaseCellMolecular,
    geneDiseaseOrganTissue,
    geneDiseaseConclusion,
    complexSetView,
    pathDetails,
    pathDetailsSmallMolecules,
    pubDetailsTriples,
    shortConceptCardReducer,
    searchCategoryTabsReducer,
    conceptCardsReducer,
    createSetReducer,
    publicationsFilterGraphReducer,
    setsVennReducer,
    setsDataSelectionReducer,
    heatMapReducer,
    compareSetsReducer,
    buildHeatmapReducer,
    projectsManagementReducer,
    projectsTabsReducer,
    publicationsManagementReducer,
    versionDialogReducer,
    // Gene Details
    geneDetails,
    geneDetailsGeneExpressionChapter,
    geneDetailsFunctionalCharacterizationChapter,
    geneDetailsPathwaysChapter,
    geneDetailsSimilarProteinsPageChapter,
    geneDetailsOrthologsChapterReducer,
    geneDetailsPhenotypesChapter,
    geneDetailsProteinStructureChapter,
    geneDetailsTherapeuticCandidatesChapter,
    geneDetailsPatentsChapter,
    geneDetailsConditionOfDysregulationChapter,
    geneDetailsClinicalTrialsChapter,
    geneDetailsDisordersChapter,
    geneDetailsProteinLocationChapter,
    geneDetailsExternalLinksChapterReducer,
    geneDetailsAntibodiesChapterReducer,
    samplesReducer,
    cellTypeClusterModalReducer,
    literatureSearchReducer,
    geneDetailsInternalLinksChapterReducer,
    // Search
    searchConceptPopupReducer,
    // Relation Map
    relationMapManagement,
    relationMapProject,
    intermediateConceptsReducer,
    hideSourceModalReducer,
    hideRelationTypesModalReducer,
    // Common Components
    addTag,
    addConcept,
    selectGene,
    selectDisease,
    tooltipReducer,
    setAnalysisGenesDetail,
    publicationsListReducer,
    freeAccountLimitDialogReducer,
    // Sets
    saveAsSetReducer,
    unresolvedConceptsReducer,
    searchWithSetsPageReducer,
    editSetReducer,
    findRelatedReducer,
    enrichmentAnalysisParamsReducer,
    rankSelectionReducer,
    rankSelectionResultReducer,
    backgroundSet,
    // Workflows
    workflowsReducer,
    bispecificAntibodyReducer,
    singleTargetForCancerReducer,
    singleCellStudyReducer,
    datasetsOptionsReducer,
    cellLineSelectionReducer,
    // Indication Diseases Finder
    indicationDiseasesFinderReducer,
    // Upload
    uploadFileReducer,
    //Association score
    c2cIndirectReducer,
    //Antibodies page
    antibodiesReducer,
  });
}

export default createMainReducer;
