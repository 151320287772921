import { call, put } from 'redux-saga/effects';

import Api from '../../../../../Api/Api';
import { getMyProjectManagementDataAction } from '../../reducer';
import {checkProjectTab} from '../../../../Projects/ProjectsTabs/utils';
import {ProjectTypes} from '../../enum';
import {toastSuccessAction} from '../../../../../toast/toastReducer';

const typeSetConst = 'SET';

export function* removeSetManagementDataItemWorker({ payload }) {
  try {
    const { setIdTypes, projectId } = payload;
    const isProjectTab = checkProjectTab(projectId);

    const groupedIds = setIdTypes.reduce((acc, { id, projectType }) => {
      let entityType = projectType;
      if (projectType === ProjectTypes.SIMPLE_SET || projectType === ProjectTypes.EFFECT_SET || projectType === ProjectTypes.COMPLEX_SET)
        entityType = typeSetConst;

      if (!acc[entityType]) {
        acc[entityType] = []; // Initialize an empty array if the type doesn't exist
      }
      acc[entityType].push(id); // Add the id to the array for the given type
      return acc;
    }, {});

    for (const [type, ids] of Object.entries(groupedIds)) {
      switch (type) {
        case typeSetConst:
          if (!isProjectTab)
            yield call(Api.deleteSets, ids);
          else
            yield call(Api.deleteProjectSet, { projectId, ids });
          break;
        case ProjectTypes.RELATION_MAP:
          if (!isProjectTab)
            yield call(Api.deleteRelationMapManagementItems, { ids, projectId });
          else
            yield call(Api.deleteProjectRelationMapManagementItems, { ids, projectId });
          break;
        case ProjectTypes.ANALYTICS:
          if (!isProjectTab)
            yield call(Api.deleteAnalytics, ids);
          else
            yield call(Api.deleteProjectAnalytics, { projectId, ids });
          break;
      }
    }

    yield put(getMyProjectManagementDataAction({ noLoader: true, projectId }));
    yield put(toastSuccessAction('Project(s) successfully deleted'));
  } catch (e) {
    console.log(e.message);
  }
}
