import React from 'react';
import PropTypes from 'prop-types';

// Components
import CreateSet from '../CreateSet/CreateSet';
import {useLocation} from 'react-router-dom';
import {apiTypes} from '../CreateSet/enums';
import {getCreateSetCategory, getCreateSetParams} from './Utils';
import './styles.scss';
import {connect} from 'react-redux';
import {PortalActions} from '../../Search/enums';
import {RELATION_MAP_CONCEPTS_LIMIT} from '../../RelationMap/RelationMapPage/constants';

const propTypes = {
  config: PropTypes.instanceOf(Object),
};

const CreateSetPage = (props) => {
  const {
    config,
  } = props;

  const location = useLocation();
  const urlParamsObject = getCreateSetParams(location.search);
  const setConfig = {
    ...config,
    apiType: urlParamsObject.savedSetId ? apiTypes.filterWithConcepts : apiTypes.targetsForCancer,
    categoryName: getCreateSetCategory(apiTypes.targetsForCancer),
  };
  if (urlParamsObject.startConceptId) {
    setConfig.conceptIds = [+urlParamsObject.startConceptId];
    setConfig.startConcepts = [{ id: urlParamsObject.startConceptId, name: urlParamsObject.startConceptName}];
  }

  return (
    <div className="create-set-page">
      <CreateSet
        config={setConfig}
        pageStyles={true}
        conceptsLimitMessage={`You can export to relation map no more than ${RELATION_MAP_CONCEPTS_LIMIT} concepts. Maximum amount of concepts on map is ${RELATION_MAP_CONCEPTS_LIMIT}.`}
        genesOnly={urlParamsObject.action === PortalActions.MARKERS_IDENTIFY_DISEASE}
        loadSet={{id: urlParamsObject.savedSetId, projectId: urlParamsObject.projectId}}
      />
    </div>
  );
};

CreateSetPage.propTypes = propTypes;

export default connect()(CreateSetPage);
