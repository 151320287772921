import {createAction, handleActions} from 'redux-actions';
import {fromJS} from 'immutable';

export const toastSuccessAction = createAction('@@toast/SUCCESS');
export const clearSuccessToastAction = createAction('@@toast/CLEAR_SUCCESS');

const routeInitialState = fromJS({
  success: null,
});

const toastReducer = handleActions(
  {
    [toastSuccessAction]: (state, { payload }) => (
      state.update('success', () => payload)
    ),
    [clearSuccessToastAction]: (state) => (
      state.update('success', () => null)
    ),
  }, routeInitialState
);

export default toastReducer;
