import React, { useEffect } from 'react';
import { Field, reduxForm } from 'redux-form/lib/immutable';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Components
import FormSelect from '../../../../common/FormElements/FormSelect';
import FormInput from '../../../../common/FormElements/FormInput';
import FormTextarea from '../../../../common/FormElements/FormTextarea';
import MultiCheckboxes from '../../../../common/Inputs/MultiCheckboxes/MultiCheckboxes';
import Button from '../../../../common/Buttons/Button/Button';
// Utils
import { ProjectStatusOptions } from '../../constants';
// Styles
import './styles.scss';

const propTypes = {
  usersData: PropTypes.instanceOf(Array),
  data: PropTypes.instanceOf(Object),
  userNameFilter: PropTypes.string,
  handleFormSubmit: PropTypes.func,
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  initialize: PropTypes.func,
  type: PropTypes.string,
  valid: PropTypes.bool,
  isAdminRights: PropTypes.bool,
  filterUsers: PropTypes.func,
};

const ProjectForm = (props) => {
  const {
    handleSubmit,
    usersData,
    userNameFilter,
    valid,
    submitting,
    handleFormSubmit,
    initialize,
    type,
    data,
    filterUsers,
    isAdminRights,
  } = props;

  useEffect(() => () => filterUsers(''), []);

  useEffect(() => {
    initialize({
      name: data.name,
      status: data.status,
      description: data.description,
      users: data.users ? data.users.map(user => ({ id: user })) : [],
    });
  }, [data, type]);

  const applyBtnClass = classNames(
    'button button-primary project-form__btn',
    { 'button-primary--disabled': !valid },
  );

  return (
    <form
      className="project-form__form"
      onSubmit={handleSubmit(handleFormSubmit)}
    >
      <div className="project-form__wrap">
        <div className="project-form__input">
          <div className="project-form__label">
            Folder Name
            <span className="mandatory-span">
              <sup>*</sup>
            </span>
          </div>
          <Field
            id="projectName"
            type="text"
            name="name"
            component={FormInput}
            placeholder="Folder Name"
          />
        </div>
        <div className="project-form__input">
          <div className="project-form__label">
            Status
            <span className="mandatory-span">
              <sup>*</sup>
            </span>
          </div>
          <Field
            id="projectStatus"
            name="status"
            placeholder="Status"
            component={FormSelect}
            options={ProjectStatusOptions}
          />
        </div>
        <div className="project-form__input">
          <div className="project-form__label">
            Description
          </div>
          <Field
            id="projectDescription"
            type="text"
            rows="5"
            name="description"
            placeholder="Description"
            component={FormTextarea}
          />
        </div>
        <div className="project-form__label">
          Share folder with
        </div>
        {
          isAdminRights &&
          <Field
            id="users-select"
            type="checkbox"
            name="users"
            options={usersData}
            component={MultiCheckboxes}
            customClassName="project-form__multicheckboxes"
            filterValue={userNameFilter}
            filter={filterUsers}
          />
        }
        <div className="project-form__controls">
          <Button
            type="submit"
            text="Submit"
            disabled={submitting}
            customClassName={applyBtnClass}
          />
        </div>
      </div>
    </form>
  );
};

ProjectForm.propTypes = propTypes;

/*function validateForm(values) {//its just not working for create project
  const errors = {};
  if (!values.get('name') || values.get('name').trim() === '') {
    errors.name = 'Enter folder name';
  }
  if (!values.get('status')) {
    errors.status = 'Select folder status';
  }
  return errors;
}*/


const composition = compose(
  reduxForm({
    form: 'PROJECT_FORM',
    destroyOnUnmount: true,
    // validate: validateForm,
    touchOnChange: true,
    enableReinitialize: true,
  }),
);

export default composition(ProjectForm);
