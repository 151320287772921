import { put, call, takeLatest, select } from 'redux-saga/effects';

// Api
import Api from '../../../../Api/Api';
// Store
import * as a from './actions';
import { getEditSetRequestDataSelector } from './selectors';
import { showVersionDialogAction } from '../../../Modals/VersionModal/store/reducer';
// Utils
import { checkProjectTab } from '../../../Projects/ProjectsTabs/utils';
// Constants
import { RELATIVE_PATH } from '../../../../constantsCommon';
import {toastSuccessAction} from '../../../../toast/toastReducer';

function* getEditSetDataWorker({ payload }) {
  try {
    const { setId, projectId } = payload;
    const isProjectTab = checkProjectTab(projectId);
    const apiToCall = !isProjectTab ? Api.getSimpleOrEffectSetContent : Api.getProjectSet;
    const requestData = !isProjectTab ? setId : { projectId, setId };
    const { data } = yield call(apiToCall, requestData);
    yield put(a.setEditSetDataAction({
      ...data,
      tags: data.tags.map((name, id) => ({ id, name })),
    }));
  } catch (e) {
    yield put(a.throwEditSetDataAction(e.message));
  }
}

function* saveEditSetWorker({ payload }) {
  try {
    const { projectId, overwrite, navigate } = payload;
    const isProjectTab = checkProjectTab(projectId);
    const requestData = yield select(getEditSetRequestDataSelector);
    const apiToCall = !isProjectTab ? Api.editSet : Api.editProjectSet;
    yield call(apiToCall, {
      ...requestData,
      ...(overwrite && { overwrite }),
    });
    yield put(a.saveEditSetSucceededAction());
    navigate(`${RELATIVE_PATH}/my-projects`, { state: { redirectedProjectId: projectId }});
    yield put(toastSuccessAction('Set saved successfully'));
  } catch (e) {
    if (e.response && e.response.status === 409) {
      yield put(showVersionDialogAction());
    }
    yield put(a.saveEditSetFailedAction(e.message));
  }
}

function* editSetSaga() {
  yield takeLatest(a.getEditSetDataAction, getEditSetDataWorker);
  yield takeLatest(a.saveEditSetRequestedAction, saveEditSetWorker);
}

export default editSetSaga;
