import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';

// Utils
import { SearchSourceFilterEnum } from '../../Search/enums';
import { getDataForSearchRequest, buildSearchLink } from '../../Search/utils';
import { generateMultiRightFindLink, manageCheckedItem } from '../../Publications/PublicationsManagement/utils';
import { withRouter } from '../WithRouter/WithRouter';
// Components
import NoData from '../../common/NoData/NoData';
import Button from '../Buttons/Button/Button';
import Error from '../../common/Error/Error';
import Loader from '../../common/Loader/Loader';
import PublicationsListItem from './Components/PublicationsListItem/PublicationsListItem';
import SelectProjectModal from '../../Modals/SelectProjectModal/SelectProjectModal';
import AddToRightfindWarningModal from '../../Modals/AddToRightfindWarningModal/AddToRightfindWarningModal';
// Store
import * as ACTIONS from './store/reducer';
import * as SELECTORS from './store/selectors';
import { getRightFindUrlSuffix, isReprintDeskAllowed, isRightFindAllowed } from '../../Header/selectors';
import { savePublicationsToProjectAction } from '../../Modals/SelectProjectModal/store/actions';
// Styles
import './PublicationsList.scss';
import { getProjectsOptionsSelector } from '../../Projects/ProjectsTabs/store/selectors';
import { getProjectsOptionsAction, resetProjectsOptionsAction } from '../../Projects/ProjectsTabs/store/reducer';

const propTypes = {
  names: PropTypes.instanceOf(Array),
  ids: PropTypes.instanceOf(Array),
  totalPages: PropTypes.number,
  pageNumber: PropTypes.number,
  content: PropTypes.instanceOf(Object),
  filters: PropTypes.instanceOf(Object),
  getPublicationsListData: PropTypes.func,
  loading: PropTypes.bool,
  error: PropTypes.string,
  resetPublicationsState: PropTypes.func,
  cooccurrenceType: PropTypes.string,
  showRightFind: PropTypes.bool,
  showReprintDesk: PropTypes.bool,
  locationParams: PropTypes.instanceOf(Object),
  selectedPublicationsIds: PropTypes.instanceOf(Array),
  selectedPubmedIds: PropTypes.instanceOf(Array),
  updateSelectedPublicationsIds: PropTypes.func,
  updateSelectedPubmedIds: PropTypes.func,
  resetProjectsOptions: PropTypes.func,
  getProjectsOptions: PropTypes.func,
  savePublicationsToProject: PropTypes.func,
  rightFindUrlSuffix: PropTypes.string,
  getAllPublicationsIds: PropTypes.func,
  setPublicationsListIds: PropTypes.func,
  selectAll: PropTypes.bool,
};

class PublicationsList extends React.Component {
  constructor(props) {
    super(props);
    this.conceptsNames = props.names ? props.names.join(' AND ') : null;
    this.conceptsIds = (props.ids && props.ids.length > 0) ? props.ids.join(' AND ') : null;
    this.cooccurrenceType = props.locationParams.cooccurrenceType || props.cooccurrenceType || SearchSourceFilterEnum.ALL;
    this.state = {
      showSelectProjectModal: false,
      showAddToRightfindWarningModal: false,
    };
  }

  componentDidMount() {
    const { getPublicationsListData, getProjectsOptions } = this.props;
    const requestData = this.generateRequestData();
    getPublicationsListData(requestData);
    getProjectsOptions();
  }

  componentWillUnmount() {
    const { resetPublicationsState, resetProjectsOptions } = this.props;
    resetPublicationsState();
    resetProjectsOptions();
  }

  getSearchLink = () => {
    const { filters } = this.props;
    const { pathname, search } = buildSearchLink({
      plainValue: this.conceptsNames,
      exactValue: this.conceptsIds,
      publicationSource: this.cooccurrenceType,
      ...filters,
    });
    return `${pathname}?${search}`;
  };

  generateRequestData = (data) => {
    const { filters } = this.props;
    return getDataForSearchRequest({
      plainValue: this.conceptsNames,
      exactValue: this.conceptsIds,
      publicationSource: this.cooccurrenceType,
      ...filters,
      ...data,
    });
  };

  handlePageChange = ({ selected: page }) => {
    const { getPublicationsListData } = this.props;
    const requestData = this.generateRequestData({ page });
    getPublicationsListData(requestData);
  };

  checkPublication = ({ id, pmid }) => {
    const {
      selectedPubmedIds,
      updateSelectedPubmedIds,
      selectedPublicationsIds,
      updateSelectedPublicationsIds,
    } = this.props;
    const newSelectedPublicationsIds = manageCheckedItem(id, selectedPublicationsIds);
    updateSelectedPublicationsIds(newSelectedPublicationsIds);

    if (pmid) {
      const newSelectedPubmedIds = manageCheckedItem(pmid, selectedPubmedIds);
      updateSelectedPubmedIds(newSelectedPubmedIds);
    }
  };

  handleSavingPublicationsToProject = () => {
    const { savePublicationsToProject, selectedPublicationsIds, setPublicationsListIds } = this.props;
    savePublicationsToProject({
      ids: selectedPublicationsIds,
    });
    setPublicationsListIds({
      selectedPublicationsIds: [],
      selectedPubmedIds: [],
      selectAll: false,
    });
  };

  openSelectProjectModal = () => {
    this.setState({
      showSelectProjectModal: true,
    });
  };

  closeSelectProjectModal = () => {
    this.setState({
      showSelectProjectModal: false,
    });
  };

  goRightFind = () => {
    const { selectedPubmedIds, rightFindUrlSuffix } = this.props;
    window.open(generateMultiRightFindLink(selectedPubmedIds, rightFindUrlSuffix), '_blank');
  };

  handleAddToRightfind = () => {
    const { selectedPublicationsIds, selectedPubmedIds } = this.props;
    if (selectedPublicationsIds.length !== selectedPubmedIds.length) {
      this.setState({ showAddToRightfindWarningModal: true });
    } else {
      this.goRightFind();
    }
  };

  handleSelectAll = () => {
    const { getAllPublicationsIds, setPublicationsListIds, selectAll } = this.props;
    if (!selectAll) {
      const requestData = this.generateRequestData();
      getAllPublicationsIds(requestData);
    } else {
      setPublicationsListIds({
        selectedPublicationsIds: [],
        selectedPubmedIds: [],
        selectAll: false,
      });
    }
  };

  render() {
    const {
      totalPages,
      pageNumber,
      content,
      loading,
      error,
      showRightFind,
      showReprintDesk,
      selectedPubmedIds,
      selectedPublicationsIds,
      rightFindUrlSuffix,
      selectAll,
    } = this.props;

    const { showSelectProjectModal, showAddToRightfindWarningModal } = this.state;

    return (
      <div className="publications-list">
        <div className="publications-list__title title3">
          {`Publications for ${this.conceptsNames ? this.conceptsNames : 'concepts'}`}
        </div>
        <div className="publication-common-links-wrapper">
          <Link
            to={this.getSearchLink()}
            target="blank"
            className="publications-list__redirect link"
          >
            Open full search page
          </Link>
        </div>
        {
          !!content.length &&
          <div className="publications-list__controls">
            {
              totalPages > 1 &&
              <div className="controls-block">
                <div className="paginationContainer">
                  <ReactPaginate
                    previousLabel="previous"
                    nextLabel="next"
                    breakClassName="break-me"
                    pageCount={totalPages}
                    forcePage={pageNumber}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={5}
                    onPageChange={this.handlePageChange}
                    containerClassName="pagination"
                    subContainerClassName="pages pagination"
                    activeClassName="active"
                  />
                </div>
              </div>
            }
            <div className="publications-list__controls-wrap">
              <Button
                onClick={this.handleSelectAll}
                customClassName="button-light"
                text={selectAll ? 'Deselect all' : 'Select all'}
              />
              {
                showRightFind &&
                <Button
                  onClick={this.handleAddToRightfind}
                  customClassName="button-light mr-5"
                  disabled={selectedPubmedIds.length < 1}
                  text="Add to RightFind"
                />
              }
              <Button
                onClick={this.openSelectProjectModal}
                customClassName="button-light"
                disabled={selectedPublicationsIds.length < 1}
                text="Save to References"
              />
            </div>

          </div>
        }
        {
          !loading &&
          <div className="publications-list__wrap">
            <div className="publications-list__content">
              {
                content && content.length > 0 &&
                content.map((item, i) => (
                  <PublicationsListItem
                    key={`publications-list-item-${i}`}
                    publication={item}
                    pmidList={selectedPubmedIds}
                    onChange={this.checkPublication}
                    showRightFind={showRightFind}
                    showReprintDesk={showReprintDesk}
                    rightFindUrlSuffix={rightFindUrlSuffix}
                  />
                ))
              }
              <NoData
                show={content && content.length === 0}
                customClassName="therapeutic-candidates__no-data"
              />
            </div>
          </div>
        }
        <Loader isLoading={loading && !error} />
        <Error show={error && !loading} error={error} />
        <SelectProjectModal
          isOpen={showSelectProjectModal}
          closeCb={this.closeSelectProjectModal}
          onConfirm={this.handleSavingPublicationsToProject}
        />
        <AddToRightfindWarningModal
          isOpen={showAddToRightfindWarningModal}
          closeCb={() => { this.setState({ showAddToRightfindWarningModal: false }); }}
          onConfirm={this.goRightFind}
        />
      </div>
    );
  }
}

PublicationsList.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    projectsOptions: getProjectsOptionsSelector(state),
    content: SELECTORS.getPublicationsDataSelector(state),
    totalPages: SELECTORS.getPublicationsManagementTotalPagesSelector(state),
    pageNumber: SELECTORS.getPublicationsManagementpageNumberSelector(state),
    selectedPublicationsIds: SELECTORS.getSelectedPublicationsIdsSelector(state),
    selectedPubmedIds: SELECTORS.getSelectedPubmedIdsSelector(state),
    loading: SELECTORS.getPublicationsLoadingSelector(state),
    error: SELECTORS.getPublicationsErrorSelector(state),
    selectAll: SELECTORS.getPublicationsSelectAllSelector(state),
    showRightFind: isRightFindAllowed(state),
    showReprintDesk: isReprintDeskAllowed(state),
    rightFindUrlSuffix: getRightFindUrlSuffix(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getProjectsOptions() {
      dispatch(getProjectsOptionsAction());
    },
    resetProjectsOptions() {
      dispatch(resetProjectsOptionsAction());
    },
    savePublicationsToProject(data) {
      dispatch(savePublicationsToProjectAction(data));
    },
    getPublicationsListData(data) {
      dispatch(ACTIONS.getPublicationsListDataAction(data));
    },
    updateSelectedPublicationsIds(data) {
      dispatch(ACTIONS.updateSelectedPublicationsIdsAction(data));
    },
    updateSelectedPubmedIds(data) {
      dispatch(ACTIONS.updateSelectedPubmedIdsAction(data));
    },
    resetPublicationsState() {
      dispatch(ACTIONS.resetPublicationsStateAction());
    },
    getAllPublicationsIds(data) {
      dispatch(ACTIONS.getPublicationsListIdsction(data));
    },
    setPublicationsListIds(data) {
      dispatch(ACTIONS.setPublicationsListIdsAction(data));
    },
  };
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(PublicationsList));
