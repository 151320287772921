import { createSelector } from 'reselect';
import { formatDate } from '../Utils/Utils';

const user = state => state.getIn(['auth', 'user', 'username']);
const userData = state => state.getIn(['auth', 'user']);
const userToken = state => state.getIn(['auth', 'user', 'token']);
const headerReducer = state => state.getIn(['headerReducer']);

const successToast = state => state.getIn(['toasts', 'success']);

export const getUserData = createSelector(
  userData,
  data => data
);

export const getUserName = createSelector(
  user,
  headerReducer,
  (userData, headerUserData) => userData || headerUserData.user.getIn(['currentUser', 'username'])
);

export const getUserTokenSelector = createSelector(
  userToken,
  (data) => data
);

export const getUserFullName = createSelector(
  headerReducer,
  data => (
    data.user.getIn(['currentUser', 'fullName'])
      || data.user.getIn(['currentUser', 'credentials', 'userId'])
  )
);

export const getUserAccountData = createSelector(
  headerReducer,
  data => ({
    accountType: data.user.getIn(['currentUser', 'accountType']),
    expiration: formatDate(data.user.getIn(['currentUser', 'expiration'])),
  })
);

export const getUserId = createSelector(
  headerReducer,
  data => (
    data.user.getIn(['currentUser', 'id'])
  )
);

export const getUserEmail = createSelector(
  headerReducer,
  data => (
    data.user.getIn(['currentUser', 'credentials', 'userId'])
  )
);

export const getUserProfileOptions = createSelector(
  headerReducer,
  data => (
    data.user.getIn(['currentUser', 'userProfileOptions'], [])
  )
);

export const getRightFindUrlSuffix = createSelector(
  getUserProfileOptions,
  (data) => {
    const rightfindOption = data.find(profileOption => profileOption.get('option') === 'RIGHTFIND_URL');
    return rightfindOption ? `&${rightfindOption.get('optionData')}` : '';
  }
);

export const getWorkflowsAlgorithms = createSelector(
  headerReducer,
  (data) => {
    const permissions = data.user.getIn(['currentUser', 'permissions']) || [];
    const filteredPermissions = permissions.filter(permission => permission.get('name').startsWith('TARGET_DISCOVERY_'));
    return filteredPermissions.size > 0 ? filteredPermissions.toJS() : filteredPermissions;
  }
);

export const isWorkflowsAllowed = createSelector(
  headerReducer,
  (data) => {
    const permissions = data.user.getIn(['currentUser', 'permissions']) || [];
    return permissions.find(permission => permission.get('name').startsWith('TARGET_DISCOVERY_')) !== undefined;
  }
);

export const isSingleCellAllowedSelector = createSelector(
  headerReducer,
  (data) => {
    const permissions = data.user.getIn(['currentUser', 'permissions']) || [];
    return permissions.find(permission => permission.get('name') === 'TARGET_DISCOVERY_SINGLE_CELL_STUDY') !== undefined;
  }
);

export const isRightFindAllowed = createSelector(
  headerReducer,
  (data) => {
    const permissions = data.user.getIn(['currentUser', 'permissions']) || [];
    return permissions.find(permission => permission.get('name').startsWith('RIGHT_FIND')) !== undefined;
  }
);

export const isReprintDeskAllowed = createSelector(
  headerReducer,
  (data) => {
    const permissions = data.user.getIn(['currentUser', 'permissions']) || [];
    return permissions.find(permission => permission.get('name').startsWith('ARTICLE_GALAXY')) !== undefined;
  }
);

export const isAntibodiesAllowed = createSelector(
  headerReducer,
  (data) => {
    const permissions = data.user.getIn(['currentUser', 'permissions']) || [];
    return permissions.find(permission => permission.get('name').startsWith('FEATURE_ANTIBODIES')) !== undefined;
  }
);

export const isFreeUserSelector = createSelector(
  headerReducer,
  data => (
    data.user.getIn(['currentUser', 'accountType']) === 'Free'
  )
);

const changePasswordModalKey = state => state.getIn(['changePassword', 'showModal']);

export const getChangePasswordModalKey = createSelector(
  changePasswordModalKey,
  data => data
);

export const getCerebrumST = createSelector(
  headerReducer,
  data => (
    data.cerebrumSC.get('st').toJS()
  )
);

export const getAccessMappingsSelector = createSelector(
  headerReducer,
  (data) => {
    const accessMappings = data.cerebrumSC.get('accessMappings');
    return accessMappings && accessMappings.toJS();
  }
);

export const isProjectManagerRightSelector = createSelector(
  headerReducer,
  (data) => {
    const permissions = data.user.getIn(['currentUser', 'permissions']) || [];
    return permissions.find(permission => permission.get('name').startsWith('PROJECT_MANAGER')) !== undefined;
  }
);

export const getCurrentUserSelector = createSelector(
  headerReducer,
  (data) => {
    const currentUser = data.user.get('currentUser');
    return currentUser && currentUser.toJS();
  }
);

export const getCurrentFindRelatedMethodSelector = createSelector(
  headerReducer,
  (data) => {
    return data.user && data.user.toJS().lastEnrichmentAnalysisSelected;
  }
);

export const getCerebrumSemanticCategoriesSelector = createSelector(
  headerReducer,
  data => (
    data.cerebrumSC.get('sc').toJS()
  )
);

export const getSuccessToast = createSelector(
  successToast,
  data => data
);
