import cloneDeep from 'lodash.clonedeep';
import {
  call,
  put,
  takeEvery,
  takeLatest,
  select,
} from 'redux-saga/effects';

// Api
import Api from '../../../../Api/Api';
// Utils
import { NOT_SIMPLE_ERROR } from '../../../common/UploadFile/constants';
import { transformFileDataToConcepts } from '../../../common/UploadFile/utils';
import { generateSetForAnalysis, getTargetTissuesToSet } from './utils';
import { rankingCategoryRequestEnum, rankingCategoryEnum } from './enum';
import { formatNetworkAnalysisHeatmapData } from '../../../graphics/Heatmap/utils';
import { HeatmapTypeEnum } from '../../../graphics/Heatmap/constants';
import { checkProjectTab, getProjectIdForRequest } from '../../../Projects/ProjectsTabs/utils';
import { getFullTypeByID, checkIsReturnToThePage } from '../../../Utils/Utils';
import { methodTabEnum } from '../SetAnalysisMethodSelection/enum';
// Store
import { getUserId, getUserEmail } from '../../../Header/selectors';
import { selectedDataForAnalysis } from '../../../Sets/SetsDataSelection/selectors';
import { getHeatmapInitialDataSelector } from '../../../graphics/Heatmap/store/selectors';
import {
  getBackgroundSetConfigSelector,
  getMethodTab,
  getSelectedType,
  getAllTissues,
  getNetworkAnalysisTissuesForMenu,
} from '../SetAnalysisMethodSelection/selectors';
import {
  setRankingAction,
  setBackgroundSetFilterOptionAction,
  tabsStateAction,
  updateBackgroundSetConfigAction,
  selectTissueType,
  tickTissueSource,
  setScoringFormula,
  changeFactorLogicValueAction,
  selectSetForNetworkAnalysisSettings,
  selectGeneForNetworkAnalysis,
  selectNetworkAnalysisTissueType,
  firstSelectedMethodAction,
  resetSetAnalysisMethodSelectionAction,
} from '../SetAnalysisMethodSelection/actions';
import { setDifferentialExpressionConcept, saveDifferentialExpressionConcept } from '../../RankSelection/DifferentialExpressionSettings/actions';
import { addAssociationScoreTerms, saveAssociationScoreTerms } from '../../RankSelection/AssociationScoreTerms/actions';
import { addLiteratureSearchTermsAction, saveLiteratureSearchTermsAction } from '../../RankSelection/LiteratureSearchTerms/actions';
import { addGeneVariationTerm, saveGeneVariationTerm } from '../../RankSelection/GeneVariationSettings/actions';
import { switchMethodSelectionLoadingFlagAction, toggleSetAnalyticsLoaderAction } from './actions';
import { getDataForRankRequest } from '../../RankSelection/SetAnalysisRankingResult/saga';
import { getNetworkAnalysisRequestParams } from '../../../Analytics/NetworkAnalysis/SetAnalysisNetworkAnalysisResult/saga';
import { setHeatmapDataAction, setHeatmapInitialDataAction } from '../../../graphics/Heatmap/store/reducer';
import { updateSaveAsSetConceptsAction } from '../../../Sets/SaveAsSet/store/actions';
import { throwUploadFileErrorAction } from '../../../common/UploadFile/store/actions';
import {
  ANALYTICS_SAVE_REQUESTED,
  ANALYTICS_SAVE_SUCCEEDED,
  ANALYTICS_SAVE_FAILED,
  ANALYTICS_INIT_REQUESTED,
  ANALYTICS_INIT_SUCCEEDED,
  ANALYTICS_INIT_FAILED,
  ANALYTICS_LOADING,
  SELECT_SET_FOR_SET_ANALYSIS_WITH_API_CALL,
  SELECT_SET_FOR_SET_ANALYSIS,
  SET_LAST_SAVE_ANALYTICS_ID,
  GET_FILE_DATA_FOR_SET_ANALYSIS,
} from './constants';
import {
  getAnalyticsTags,
  getCreatedDate,
  getReportId,
  getActiveSets,
  getComment,
  getReportTitle,
  startSetSelector,
  setAnalysisSets,
  getVersion,
  getLastSavedAnalyticsId,
} from './selectors';
import { showVersionDialogAction } from '../../../Modals/VersionModal/store/reducer';
import { getLocationHistorySelector } from '../../../../location/locationSelector';
import { selectedMethodSelector } from '../SetAnalysisResults/selectors';
import { analysisMethodEnum } from '../../FindRelated/FindRelatedResult/enums';
import {toastSuccessAction} from '../../../../toast/toastReducer';

function* saveAnalytics(action) {
  try {
    const { projectId, overwrite, retryUnblockPage } = action.data;
    yield put({ type: ANALYTICS_LOADING, data: true });
    const userId = yield select(getUserId);
    const id = yield select(getReportId);
    const tags = yield select(getAnalyticsTags);
    const created = yield select(getCreatedDate);
    const title = yield select(getReportTitle);
    const authorEmail = yield select(getUserEmail);
    const sets = yield select(getActiveSets);
    const selectedConcepts = yield select(selectedDataForAnalysis);
    const comment = yield select(getComment);
    const methodTab = yield select(getMethodTab);
    const selectedAnalysisMethod = yield select(selectedMethodSelector);
    const backgroundSetConfiguration = yield select(getBackgroundSetConfigSelector);
    const { id: selectedSemanticType } = yield select(getSelectedType);
    const version = yield select(getVersion);
    const rankSelectionData = yield getDataForRankRequest({
      data: { concepts: selectedConcepts.map(item => item.id) },
    });
    const { post: networkAnalysisData } = yield getNetworkAnalysisRequestParams({
      post: {
        startSetIds: selectedConcepts.map(item => item.id),
      },
    });

    const selectedMethod = {
      findRelatedData: {
        analysisMethod: selectedAnalysisMethod !== analysisMethodEnum.RANKING
        && selectedAnalysisMethod !== analysisMethodEnum.NETWORK
          ? selectedAnalysisMethod
          : null,
        backgroundSetConfiguration,
        selectedSemanticType,
      },
      methodTab,
      networkAnalysisData,
      rankSelectionData,
    };

    const startSet = sets && sets[0].setType === 'EFFECT' ? sets[0].id : yield select(startSetSelector);
    const conceptSets = sets.map(set => (
      {
        color: set.color,
        name: set.name,
        type: set.setType || set.type,
        concepts: set.originalData.map(concept => (
          {
            id: concept.id,
            name: concept.name,
            score: concept.measure || concept.score || null,
          }
        )),
      }
    ));

    let post = {
      authorEmail,
      comment,
      conceptSets,
      created,
      id,
      screenshot: action.data.screenshot,
      startSet,
      selectedIds: selectedConcepts.map(item => item.id),
      tags: tags.map(tag => tag.name),
      title,
      userId,
      version,
      overwrite,
      selectedMethod,
      heatmap: yield select(getHeatmapInitialDataSelector),
      projectId: getProjectIdForRequest(projectId),
    };

    if (created) {
      post = Object.assign(post, { created });
    }
    const isProjectTab = checkProjectTab(projectId);
    const apiToCall = isProjectTab ? Api.saveProjectAnalytics : Api.saveAnalytics;
    const response = yield call(apiToCall, { post });
    yield put({ type: SET_LAST_SAVE_ANALYTICS_ID, data: { reportId: response.data.id, version: response.data.version } } );
    window.localStorage.setItem(`${id}_version`, JSON.stringify(response.data.version || 0));
    yield put({ type: ANALYTICS_SAVE_SUCCEEDED });
    retryUnblockPage();
    yield put(toastSuccessAction('Analytics successfully saved'));
  } catch (e) {
    if (e.response && e.response.status === 409) {
      yield put(showVersionDialogAction());
    } else {
      yield put({ type: ANALYTICS_SAVE_FAILED });
    }
  }
}

function* initAnalytics(action) {
  try {
    const { id, projectId } = action.data;
    const isProjectTab = checkProjectTab(projectId);
    const apiToCall = isProjectTab ? Api.initProjectAnalytics : Api.initAnalytics;
    yield put(switchMethodSelectionLoadingFlagAction(true));
    const response = yield call(apiToCall, { id, projectId });
    const { data: { selectedMethod, heatmap, version = 0 } } = response;

    const storagedVersion = localStorage.getItem(`${id}_version`) && JSON.parse(localStorage.getItem(`${id}_version`));
    const locationHistory = yield select(getLocationHistorySelector);
    const lastSavedAnalyticsId = yield select(getLastSavedAnalyticsId);
    if (checkIsReturnToThePage(locationHistory) || lastSavedAnalyticsId === id && (!!version && storagedVersion === version && lastSavedAnalyticsId === id)) {
      yield put(toggleSetAnalyticsLoaderAction(false));
      yield put(switchMethodSelectionLoadingFlagAction(false));
      return;
    }

    yield put(resetSetAnalysisMethodSelectionAction());

    yield put({ type: ANALYTICS_INIT_SUCCEEDED, data: response.data });

    localStorage.setItem(`${id}_version`, JSON.stringify(version));

    if (heatmap.conceptIds.length && heatmap.connectedConceptIds.length) {
      yield put(setHeatmapInitialDataAction({
        conceptIds: heatmap.conceptIds,
        connectedConceptIds: heatmap.connectedConceptIds,
      }));
    } else if (selectedMethod.networkAnalysisData.heatmap) {
      yield put(setHeatmapDataAction({
        data: formatNetworkAnalysisHeatmapData(selectedMethod.networkAnalysisData.heatmap),
        dataForSave: selectedMethod.networkAnalysisData.heatmap,
        type: HeatmapTypeEnum.SCORE,
      }));
    }

    /* This part for setting Method selection prev search params */
    /* Enrichment analysis part */
    const { data = [] } = yield call(Api.getAllSets);
    if (selectedMethod && selectedMethod.findRelatedData.backgroundSetConfiguration) {
      if (selectedMethod.findRelatedData.backgroundSetConfiguration.backgroundSetType === 'SET') {
        yield put(setBackgroundSetFilterOptionAction(data.find(item =>
          item.id === selectedMethod.findRelatedData.backgroundSetConfiguration.setId).name));
      }
      yield put(updateBackgroundSetConfigAction(selectedMethod.findRelatedData.backgroundSetConfiguration));
    }
    /* Rank Selection part */
    if (selectedMethod && selectedMethod.rankSelectionData && selectedMethod.rankSelectionData.rankingItems.length) {
      const { rankingItems = [] } = selectedMethod.rankSelectionData;
      for (let i = 0; i < rankingItems.length; i++) { //eslint-disable-line
        yield put(setRankingAction({ [rankingCategoryRequestEnum[rankingItems[i]]]: true }));
        if (rankingItems[i] === rankingCategoryEnum.DIFFERENTIAL_EXPRESSION) {
          yield put(setDifferentialExpressionConcept(selectedMethod.rankSelectionData.differentialExpression));
          yield put(saveDifferentialExpressionConcept());
        } else if (rankingItems[i] === rankingCategoryEnum.ASSOCIATION_SCORE) {
          const ids = selectedMethod.rankSelectionData.associationScore.conceptsGis || [];
          const conceptsDetails = yield call(Api.getShortConceptsDetails, { post: ids });
          for (let j = 0; j < conceptsDetails.data.length; j++ ) { //eslint-disable-line
            yield put(addAssociationScoreTerms(conceptsDetails.data[j]));
          }
          yield put(saveAssociationScoreTerms());
        } else if (rankingItems[i] === rankingCategoryEnum.LITERATURE_SEARCH) {
          const { phrases = [] } = selectedMethod.rankSelectionData.literatureSearch;
          for (let k = 0; k < phrases.length; k++) { //eslint-disable-line
            yield put(addLiteratureSearchTermsAction(phrases[k]));
          }
          yield put(saveLiteratureSearchTermsAction());
        } else if (rankingItems[i] === rankingCategoryEnum.GENE_VARIATION) {
          yield put(addGeneVariationTerm(selectedMethod.rankSelectionData.geneVariation));
          yield put(saveGeneVariationTerm());
        } else if (rankingItems[i] === rankingCategoryEnum.EXPRESSION_SPECIFICITY) {
          const { targetTissues, scoreFormula } = selectedMethod.rankSelectionData.expressionSpecificity;
          const { gi } = targetTissues[0];
          const allTissues = yield select(getAllTissues);
          const { tissues: selectedTissues } = allTissues.find(item => item.tissues.find(tissues => tissues.gi === gi));
          const selectedTissue = selectedTissues.find(item => item.gi === gi);
          yield put(selectTissueType(selectedTissue));
          yield put(setScoringFormula(scoreFormula));
          yield put(tickTissueSource(getTargetTissuesToSet(targetTissues)));
        }
      }
    }
    /* Network Analysis part */
    if (selectedMethod && selectedMethod.networkAnalysisData.tfLogic) {
      yield put(changeFactorLogicValueAction(selectedMethod.networkAnalysisData.tfLogic));
    }
    if (selectedMethod.networkAnalysisData.targetGeneId) {
      const conceptsDetails = yield call(Api.getShortConceptsDetails, { post: [selectedMethod.networkAnalysisData.targetGeneId] });
      yield put(selectGeneForNetworkAnalysis(conceptsDetails.data[0]));
    }
    if (selectedMethod.networkAnalysisData.additionalSet) {
      const setData = data.find(item => item.id === selectedMethod.networkAnalysisData.additionalSet);
      yield put(selectSetForNetworkAnalysisSettings(setData.id));
    }
    if (selectedMethod.networkAnalysisData.targetTissue) {
      const { gi } = selectedMethod.networkAnalysisData.targetTissue;
      const allTissues = yield select(getNetworkAnalysisTissuesForMenu);
      const selectedTissue = allTissues.find(item => item.id === gi);
      if (selectedTissue) {
        yield put(selectNetworkAnalysisTissueType(selectedTissue));
      }
      // yield put(tickNetworkAnalysisTissueSource(
      //   getTargetTissuesToSet([selectedMethod.networkAnalysisData.targetTissue])
      // ));
    }
    const { methodTab = methodTabEnum.FIND_RELATED } = response.data.selectedMethod || {};

    let firstSelectedMethod = '';
    switch (methodTab) {
      case methodTabEnum.FIND_RELATED:
        firstSelectedMethod = selectedMethod.findRelatedData.analysisMethod;
        break;
      case methodTabEnum.RANK_SELECTION:
        firstSelectedMethod = analysisMethodEnum.RANKING;
        break;
      case methodTabEnum.NETWORK_ANALYSIS:
        firstSelectedMethod = analysisMethodEnum.NETWORK;
        break;
      default:
        firstSelectedMethod = '';
    }

    yield put(firstSelectedMethodAction(firstSelectedMethod));
    yield put(switchMethodSelectionLoadingFlagAction(false));
    yield put(tabsStateAction(methodTab));
    yield put({ type: ANALYTICS_LOADING, data: false });
  } catch (e) {
    yield put(switchMethodSelectionLoadingFlagAction(false));
    yield put({ type: ANALYTICS_INIT_FAILED, message: e.message });
  }
}

function* selectSetForSetAnalysisWithApiCall(action) {
  try {
    const { id, selectedSets, projectId } = action.data;
    const setsForAnalysis = yield select(setAnalysisSets);
    const newArr = cloneDeep(setsForAnalysis);
    const selectedSetsWithItems = [];
  
    for (let i = 0; i < selectedSets.length; i++) { // eslint-disable-line no-plusplus
      const selectedSet = selectedSets[i];
      const {
        setType,
        id: selectedSetId,
      } = selectedSet;
  
      switch (setType) {
        case 'COMPLEX':
          break;
        case 'SIMPLE':
        case 'EFFECT': {
          const isProjectTab = checkProjectTab(projectId);
          const apiToCall = isProjectTab ? Api.getProjectSet : Api.getSimpleOrEffectSetContent;
          const requestData = isProjectTab ? { projectId, setId: selectedSetId } : selectedSetId;
          const { data } = yield call(apiToCall, requestData);
          const { items } = data;
          selectedSetsWithItems.push({
            ...selectedSet,
            setType,
            items,
          });
          break;
        }
        default:
      }
    }
  
    const generatedData = generateSetForAnalysis(selectedSetsWithItems);
    Object.assign(newArr[id], generatedData);
    yield put({ type: SELECT_SET_FOR_SET_ANALYSIS, data: newArr });
  } catch (error) {
    console.log(error);
  }
}

function* getFileDataForSetAnalysisWorker({ payload }) {
  try {
    const { fileData, fileType } = payload;
    if (fileData.isSimple) {
      const conceptType = getFullTypeByID(fileType);
      const concepts = transformFileDataToConcepts(fileData.simpleSet.content, conceptType);
      yield put(updateSaveAsSetConceptsAction(concepts));
    } else {
      yield put(throwUploadFileErrorAction(NOT_SIMPLE_ERROR));
    }
  } catch (e) {
    yield put(throwUploadFileErrorAction(e.message));
  }
}

function* setAnalysisAnalytics() {
  yield takeEvery(ANALYTICS_SAVE_REQUESTED, saveAnalytics);
  yield takeEvery(ANALYTICS_INIT_REQUESTED, initAnalytics);
  yield takeLatest(SELECT_SET_FOR_SET_ANALYSIS_WITH_API_CALL, selectSetForSetAnalysisWithApiCall);
  yield takeLatest(GET_FILE_DATA_FOR_SET_ANALYSIS, getFileDataForSetAnalysisWorker);
}

export default setAnalysisAnalytics;
