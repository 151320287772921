import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// Store
import {toast, ToastContainer} from 'react-toastify';
import {getSuccessToastSelector} from './toastSelector';

import 'react-toastify/dist/ReactToastify.css';
import {clearSuccessToastAction} from './toastReducer';

const propTypes = {
  successToast: PropTypes.string,
  clearSuccessToast: PropTypes.func,
};

const ToastComponent = (props) => {
  const {
    successToast,
    clearSuccessToast,
  } = props;

  useEffect(() => {
    toast.success(successToast, {
      position: 'bottom-left',
    });
    clearSuccessToast();
  }, [successToast]);

  return (
    <ToastContainer />
  );
};

ToastComponent.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    successToast: getSuccessToastSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    clearSuccessToast(data) {
      dispatch(clearSuccessToastAction(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ToastComponent);
