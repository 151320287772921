import { takeLatest, call, put, select } from 'redux-saga/effects';

// Api
import Api from '../../../../Api/Api';
// Utils
import {
  prepareProjectData,
  mergeLinksAndNodes,
  getHideSorceModalData,
  addCooccurrenceToLinks,
  getRelationTypesModalData,
  getLinksAndNodesFromTriples,
  calcSemanticCategoriesPosition,
} from '../utils';
import { SearchSourceFilterEnum } from '../../../Search/enums';
// Constants
import { EmptyData, LayoutAlgorhitms } from '../constants';
// Store
import * as a from './actions';
import * as s from './selectors';
import { getAccessMappingsSelector } from '../../../Header/selectors';
import { setHideRelationTypesDataAction } from '../Components/HideRelationTypesModal/store/reducer';
import { getHideRelationTypesSelectedIdsSelector } from '../Components/HideRelationTypesModal/store/selectors';
import { setHideSourceDataAction } from '../Components/HideSourceModal/store/reducer';
import { getHideSourceSelectedDataIdsSelector } from '../Components/HideSourceModal/store/selectors';
import { showFreeAccountLimitDialogAction } from '../../../common/FreeAccountLimitationDialog/store/reducer';
import { showVersionDialogAction } from '../../../Modals/VersionModal/store/reducer';
// Utils
import { checkProjectTab, getProjectIdForRequest } from '../../../Projects/ProjectsTabs/utils';
import {toastSuccessAction} from '../../../../toast/toastReducer';

function* getRelationMapProjectDataWorker({ payload }) {
  try {
    // Project
    const { projectId, mapId } = payload;
    const isProjectTab = checkProjectTab(projectId);

    const apiRequest = isProjectTab ?
      Api.getProjectRelationMapManagementItemById :
      Api.getRelationMapManagementItemById;

    const { data: projectData } = yield call(apiRequest, { mapId, projectId });

    const selectedSources = projectData.mapOptions ? projectData.mapOptions.selectedSources : [];
    const selectedRelationTypes = projectData.mapOptions ? projectData.mapOptions.selectedRelationTypes : [];
    const conceptIds = projectData.concepts.map(d => d.conceptId);

    const { data: relatedPubs } = yield call(Api.getRelationMapConceptsPublications, conceptIds);
    const { data: closelyRelatedPubs } = yield call(Api.getRelationMapCloselyRelatedPubs, conceptIds);

    const data = prepareProjectData(projectData, selectedSources, selectedRelationTypes);
    const dataWithCooccurrence = addCooccurrenceToLinks(data.data, relatedPubs, closelyRelatedPubs);
    yield put(a.setRelationMapAllProjectDataAction({ ...data, data: dataWithCooccurrence.data }));

    // Modals
    const links = Object.values(data.data.links);
    const accessMappings = yield select(getAccessMappingsSelector);

    yield put(setHideSourceDataAction(getHideSorceModalData(links, accessMappings, selectedSources)));
    yield put(setHideRelationTypesDataAction(getRelationTypesModalData(links, selectedRelationTypes)));
  } catch (e) {
    yield put(a.throwRelationMapProjectErrorAction(e.message));
  }
}

function* addNewConceptsToRelationMapWorker({ payload }) {
  try {
    // Project
    yield put(a.toggleRelationMapLoaderAction(true));

    const newConceptGis = payload.concepts;

    const layout = yield select(s.getRelationMapLayoutSelector);
    const dimensions = yield select(s.getRelationMapDimensionsSelector);
    const existingConceptGis = yield select(s.getRelationMapConceptsIdsSelector);
    const semCategories = yield select(s.getRelationMapSemanticCategoriesMapSelector);
    const existingData = yield select(s.getRelationMapDataWithoutCooccurrenceLinksSelector);

    const conceptIds = [...existingConceptGis, ...newConceptGis];

    const { data: concepts } = yield call(Api.getConceptsByIdsArr, newConceptGis);
    const { data: uids } = yield call(Api.getTripleIdsBetweenNewConcepts, { newConceptGis, existingConceptGis });
    const { data: triples } = yield call(Api.getTriples, { post: uids });
    const { data: relatedPubs } = yield call(Api.getRelationMapConceptsPublications, conceptIds);
    const { data: closelyRelatedPubs } = yield call(Api.getRelationMapCloselyRelatedPubs, conceptIds);

    const linksAndNodes = getLinksAndNodesFromTriples(triples);
    const data = mergeLinksAndNodes(concepts, linksAndNodes, existingData, semCategories);
    const dataWithCooccurrence = addCooccurrenceToLinks(data, relatedPubs, closelyRelatedPubs);

    if (layout.algorithm === LayoutAlgorhitms.SEMANTIC_CATEGORIES) {
      const { data: { nodes, links }, semanticCategories } = dataWithCooccurrence;
      const semCategoriesPositionedNodes = calcSemanticCategoriesPosition(Object.values(nodes), Object.keys(semanticCategories), dimensions.width);

      yield put(a.setRelationMapProjectDataAction({
        data: {
          nodes: semCategoriesPositionedNodes,
          links,
        },
        semanticCategories,
      }));
    } else {
      yield put(a.setRelationMapProjectDataAction(dataWithCooccurrence));
    }

    if (!payload.noCache) {
      yield put(a.cacheRelationMapDataAction(existingData || EmptyData));
    }

    // Modals
    const links = Object.values(data.links);
    const accessMappings = yield select(getAccessMappingsSelector);
    const selectedSources = yield select(getHideSourceSelectedDataIdsSelector);
    const selectedRelationTypes = yield select(getHideRelationTypesSelectedIdsSelector);

    yield put(setHideSourceDataAction(getHideSorceModalData(links, accessMappings, selectedSources)));
    yield put(setHideRelationTypesDataAction(getRelationTypesModalData(links, selectedRelationTypes)));
  } catch (e) {
    yield put(a.throwRelationMapProjectErrorAction(e.message));
  }
}

function* getRelationMapSetDataWorker({ payload }) {
  try {
    yield put(a.toggleRelationMapLoaderAction(true));
    const { setId, projectId } = payload;
    const isProjectTab = checkProjectTab(projectId);
    const apiToCall = isProjectTab ? Api.getProjectSet : Api.getSimpleOrEffectSetContent;
    const requestData = isProjectTab ? { setId, projectId } : setId;
    const { data } = yield call(apiToCall, requestData);
    const concepts = data.items.map(d => d.id);
    yield put(a.addNewConceptsToRelationMapAction({ concepts }));
  } catch (e) {
    yield put(a.throwRelationMapProjectErrorAction(e.message));
  }
}

function* saveRelationMapWorker({ payload }) {
  try {
    const { projectId, noReset, overwrite, retryUnblockPage } = payload;
    yield put(a.toggleRelationMapLoaderAction(true));
    const isProjectTab = checkProjectTab(projectId);
    const apiToCall = isProjectTab ? Api.saveProjectRelationMapManagementItem : Api.saveRelationMapManagementItem;
    const post = yield select(s.getRelationMapDataForSaveSelector);

    const requestData = {
      ...post,
      overwrite,
      projectId: getProjectIdForRequest(projectId),
    };
    yield call(apiToCall, requestData);
    yield put(a.toggleRelationMapIsSavedAction());
    retryUnblockPage();
    if (!noReset) {
      yield put(a.resetRelationMapAction());
    }
    yield put(toastSuccessAction('Relation map saved successfully'));
  } catch (e) {
    if (e.response && e.response.status === 402) {
      yield put(showFreeAccountLimitDialogAction());
    }
    if (e.response && e.response.status === 409) {
      yield put(showVersionDialogAction());
    }
  }
}

function* getRelationMapRelatedConceptsOptionsWorker({ payload }) {
  try {
    const params = {
      page: 0,
      size: 15,
      phrase: payload,
    };
    const post = { publicationSource: SearchSourceFilterEnum.ABSTRACT };
    const { data } = yield call(Api.categoriesTabData, { params, post });
    const options = [...data.majorCategories, ...data.otherCategories].reduce((o, d) => {
      if (d.count !== 0) {
        o.push({
          value: d.categoryName,
          label: d.categoryName,
        });
      }
      return o;
    }, []);
    yield put(a.setRelationMapRelatedConceptsOptionsAction(options));
  } catch (e) {
    yield put(a.throwRelationMapProjectErrorAction(e.message));
  }
}

function* relationMapSaga() {
  yield takeLatest(a.getRelationMapProjectDataAction, getRelationMapProjectDataWorker);
  yield takeLatest(a.addNewConceptsToRelationMapAction, addNewConceptsToRelationMapWorker);
  yield takeLatest(a.getRelationMapSetDataAction, getRelationMapSetDataWorker);
  yield takeLatest(a.saveRelationMapAction, saveRelationMapWorker);
  yield takeLatest(a.getRelationMapRelatedConceptsOptionsAction, getRelationMapRelatedConceptsOptionsWorker);
}

export default relationMapSaga;
