import React from 'react';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import { createRoot } from 'react-dom/client';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';

// Styles
import 'react-virtualized/styles.css';
import './theme/styles/index.css';
import './theme/styles/atomics.css';
import './theme/styles/fa/css/font-awesome.css';
import './theme/styles/index.scss';
// Store
import configureStore from './store';
import { locationChangeRequestAction } from './location/locationReducer';
// Api
import Api from './Api/Api';
// Constants
import { RELATIVE_PATH, outerUrls } from './constantsCommon';
import { ENV } from './settings';
// Utils
import { initScript, checkHubspotTracking } from './components/Utils/Utils';
// Components
import App from './App';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import ToastComponent from './toast/ToastComponent';

export const store = configureStore();
export const history = createBrowserHistory({ basename: RELATIVE_PATH });

store.dispatch(locationChangeRequestAction(history.location));

history.listen((location) => {
  store.dispatch(locationChangeRequestAction(location.location));
  checkHubspotTracking(history.location.pathname);
});

if (window.location.href.includes('/login/genmab')) {
  window.open(outerUrls.genmab, '_self');
}

// adding track code + kekule library (because of libraries conflicts) + added setTimeout to kekule also becouse of the conflict
if (ENV === 'prod') {
  initScript('//js.hs-scripts.com/7609166.js');
} else {
  initScript('//js.hs-scripts.com/8565548.js');
}

setTimeout(() => {
  initScript('/kekule/kekule.js');
}, 1000);

checkHubspotTracking(window.location.pathname);

Api.setStore(store);

const root = createRoot(document.getElementById('root'));

window.addEventListener('beforeunload', (e) => {
  e.stopImmediatePropagation();
});

root.render(
  <Provider store={store}>
    <HistoryRouter history={history}>
      <ScrollToTop>
        <App />
      </ScrollToTop>
      <ToastComponent />
    </HistoryRouter>
  </Provider>
);
