export const ProjectStatus = {
  ACTIVE: {
    value: 'ACTIVE',
    label: 'Active',
  },
  CLOSED: {
    value: 'CLOSED',
    label: 'Archived',
  },
  LOCKED: {
    value: 'LOCKED',
    label: 'Read-only',
  },
  LOCKED_FOR_USERS: {
    value: 'LOCKED_FOR_USERS',
    label: 'Read-only for others',
  },
};

export const ProjectStatusOptions = [
  {
    type: 'ACTIVE',
    value: 'ACTIVE',
    label: 'Active',
  },
  {
    type: 'CLOSED',
    value: 'CLOSED',
    label: 'Archived',
  },
  {
    type: 'LOCKED',
    value: 'LOCKED',
    label: 'Read-only',
  },
  {
    type: 'LOCKED_FOR_USERS',
    value: 'LOCKED_FOR_USERS',
    label: 'Read-only for others',
  },
];
