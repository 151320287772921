import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isEmpty from 'lodash.isempty';

// Utils
import { formatDate } from '../../../Utils/Utils';
import { generateRightFindLink, generateReprintsDeskLink } from '../../../Publications/PublicationsManagement/utils';
// Components
import Loader from '../../../common/Loader/Loader';
import Error from '../../../common/Error/Error';
import PublicationDetailsConcepts from '../PublicationDetailsConcepts/PublicationDetailsConcepts';
import Checkbox from '../../../common/Inputs/Checkbox/Checkbox';
import PublicationDetailsTitle from './PublicationDetailsTitle';
import PublicationDetailsAbstract from './PublicationDetailsAbstract';
import PublicationDetailsKeywords from './PublicationDetailsKeywords';
import Button from '../../../common/Buttons/Button/Button';
import SelectProjectModal from '../../../Modals/SelectProjectModal/SelectProjectModal';
// Store
import { getRightFindUrlSuffix, isReprintDeskAllowed, isRightFindAllowed } from '../../../Header/selectors';
import * as SELECTORS from '../../selectors';
import * as ACTIONS from '../../reducer';
import { showTooltipAction, hideTooltipAction } from '../../../common/Tooltip/store/reducer';
import { shortConceptCardSetIdAction } from '../../../Concept/ShortConceptCard/reducer';
import { savePublicationsToProjectAction } from '../../../Modals/SelectProjectModal/store/actions';
// Styles
import './styles.scss';

const propTypes = {
  data: PropTypes.instanceOf(Object),
  loading: PropTypes.bool,
  error: PropTypes.string,
  conceptsData: PropTypes.instanceOf(Object),
  showRightFind: PropTypes.bool,
  showReprintDesk: PropTypes.bool,
  pubmedId: PropTypes.string,
  pubIdFromState: PropTypes.number,
  pubId: PropTypes.string,
  sorting: PropTypes.shape({
    sortBy: PropTypes.string,
    sortDirection: PropTypes.string,
  }),
  getPublicationDetailsData: PropTypes.func,
  getPublicationDetailsByPubMedIdData: PropTypes.func,
  sortConcepts: PropTypes.func,
  changeConceptsPage: PropTypes.func,
  sliderValue: PropTypes.number,
  filteredConcepts: PropTypes.instanceOf(Array),
  setSliderValue: PropTypes.func,
  updateSemCategories: PropTypes.func,
  semanticCategories: PropTypes.instanceOf(Array),
  highlights: PropTypes.instanceOf(Object),
  savePrevHighlights: PropTypes.func,
  prevHighlights: PropTypes.instanceOf(Object),
  allSelectedHighlights: PropTypes.bool,
  toggleAllHighlights: PropTypes.func,
  showTooltip: PropTypes.func,
  hideTooltip: PropTypes.func,
  setShortConceptCardId: PropTypes.func,
  savePublicationsToProject: PropTypes.func,
  searchTermsHighlighted: PropTypes.bool,
  toggleSearchTermsHighlighted: PropTypes.func,
  rightFindUrlSuffix: PropTypes.string,
};

const PublicationDetailsInfo = (props) => {
  const {
    data,
    loading,
    error,
    showRightFind,
    showReprintDesk,
    pubId,
    pubIdFromState,
    pubmedId,
    sorting,
    sortConcepts,
    getPublicationDetailsData,
    getPublicationDetailsByPubMedIdData,
    conceptsData,
    changeConceptsPage,
    semanticCategories,
    updateSemCategories,
    highlights,
    sliderValue,
    filteredConcepts,
    setSliderValue,
    savePrevHighlights,
    prevHighlights,
    allSelectedHighlights,
    toggleAllHighlights,
    showTooltip,
    hideTooltip,
    setShortConceptCardId,
    savePublicationsToProject,
    searchTermsHighlighted,
    toggleSearchTermsHighlighted,
    rightFindUrlSuffix,
  } = props;
  const checkAdditionalInfo = data && (data.impactFactor || data.authors || data.meshHeadList);

  const [showMoreInfo, setShowMoreInfo] = useState(false);
  const [selectProjectModal, setSelectProjectModal] = useState(false);

  useEffect(() => {
    if (pubId) {
      getPublicationDetailsData(pubId);
    } else {
      getPublicationDetailsByPubMedIdData(pubmedId);
    }
  }, [getPublicationDetailsByPubMedIdData, getPublicationDetailsData, pubId, pubmedId]);

  function toggleSemanticCategory(item) {
    const { checked } = item;
    updateSemCategories({
      ...item,
      checked: !checked,
    });
    savePrevHighlights(highlights);
  }

  function renderItem(item, i) {
    const { category, color, checked } = item;

    return (
      <div className="colors__item" key={`key-sem-cat-${i}`}>
        <Checkbox
          id={`checkbox-pub-sem-cat-${i}`}
          checked={checked}
          disabled={searchTermsHighlighted}
          onChange={() => { toggleSemanticCategory(item); }}
        />
        <span className="sem-cat__text">{category}</span>
        {
          color &&
          <div
            style={{ backgroundColor: `#${color}` }}
            className="sem-cat__color"
          />
        }
      </div>
    );
  }

  function toggleSearchTermsHighlightedHandler() {
    toggleAllHighlights(false);
    savePrevHighlights(highlights);
    toggleSearchTermsHighlighted(!searchTermsHighlighted);
  }

  function toggleAllHighlightsHandler() {
    toggleSearchTermsHighlighted(false);
    toggleAllHighlights(!allSelectedHighlights);
    savePrevHighlights(highlights);
  }

  function handleSavingPublicationsToProject() {
    const publicationId = pubId || pubIdFromState;
    savePublicationsToProject({
      ids: [publicationId],
    });
  }

  function getAbstractText() {
    if (data) {
      const { abstractInfoHighlighted, abstractInfo } = data;
      return abstractInfoHighlighted && searchTermsHighlighted ?
        abstractInfoHighlighted :
        abstractInfo;
    }
    return '';
  }

  return (
    <div className="pub-details">
      {
        data && !loading && !error &&
        <div className="pub-details__wrap">
          <div className="pub-details__big-container">
            {
              !isEmpty(highlights) &&
              <div className="pub-details__sidebar">
                <div className="pub-details__sidebar-title">
                  <Checkbox
                    id="pub-details-list__search-terms--checkbox"
                    checked={searchTermsHighlighted}
                    disabled={!(data && data.abstractInfoHighlighted)}
                    onChange={toggleSearchTermsHighlightedHandler}
                  />
                  <span>Highlight search terms</span>
                </div>
                {
                  semanticCategories.length > 0 &&
                  <div className="pub-details__sidebar-sem-cat">
                    <div className="pub-details__sidebar-title">
                      <Checkbox
                        id="pub-details-list__sem-cat-checkbox"
                        checked={allSelectedHighlights}
                        onChange={toggleAllHighlightsHandler}
                      />
                      <span>Highlight categories:</span>
                    </div>
                    {semanticCategories.map(renderItem)}
                  </div>
                }
              </div>
            }
            <div className="pub-details__text-wrapper">
              <PublicationDetailsTitle
                showTooltip={showTooltip}
                hideTooltip={hideTooltip}
                titleText={data.title}
                highlights={highlights.TITLE}
                semanticCategories={semanticCategories}
                setShortConceptCardId={setShortConceptCardId}
              />
              <div className="pub-details-info">
                <div className="pub-details-info__wrap">
                  <div className="pub-details-info__column">
                    {
                      data.sourceName &&
                      <div className="pub-details-info__block">
                        <span className="pub-details-info__title">Source: </span>
                        <span className="pub-details-info__data">{data.sourceName}</span>
                      </div>
                    }
                    {
                      data.date &&
                      <div className="pub-details-info__block">
                        <span className="pub-details-info__title">Publication date: </span>
                        <span className="pub-details-info__data">{formatDate(data.date)}</span>
                      </div>
                    }
                    {
                      showMoreInfo &&
                      <>
                        {
                          data.impactFactor &&
                          <div className="pub-details-info__block">
                            <span className="pub-details-info__title">Impact factor: </span>
                            <span className="pub-details-info__data">{data.impactFactor}</span>
                          </div>
                        }
                        {
                          data.authors && data.authors.length > 0 &&
                          <div className="pub-details-info__block">
                            <span className="pub-details-info__title">Authors: </span>
                            <span className="pub-details-info__data">{data.authors.join(', ')}</span>
                          </div>
                        }
                      </>
                    }
                  </div>
                  <div className="pub-details-info__column">
                    {
                      data.keywordList && data.keywordList.length > 0 &&
                      <div className="pub-details-info__block">
                        <span className="pub-details-info__title">Keywords from Author(s): </span>
                        <PublicationDetailsKeywords
                          data={data}
                          highlights={highlights.KEYWORDS}
                          prevHighlighs={prevHighlights.KEYWORDS}
                          semanticCategories={semanticCategories}
                        />
                        {
                          showMoreInfo && data.meshHeadList && data.meshHeadList.length > 0 &&
                          <div className="pub-details-info__block">
                            <span className="pub-details-info__title">MeSH terms: </span>
                            <span className="pub-details-info__data">{data.meshHeadList.join(', ')}</span>
                          </div>
                        }
                      </div>
                    }
                  </div>
                </div>
                {
                  checkAdditionalInfo &&
                  <span
                    className="pub-details-info__show-block"
                    onClick={() => { setShowMoreInfo(!showMoreInfo); }}
                  >
                    { showMoreInfo ? 'show less' : 'show more' }
                  </span>
                }
              </div>
              <div className="pub-details-info__management-btns">
                {
                  data.pmcId &&
                  <Button
                    target="_blank"
                    href={`https://www.ncbi.nlm.nih.gov/pmc/articles/${data.pmcId}/`}
                    customClassName="button-light mr-5"
                    text="Full text article"
                  />
                }
                {
                  data.url &&
                  !data.pubmedId &&
                  <Button
                    target="_blank"
                    href={data.url}
                    customClassName="button-light mr-5"
                    text="Go to Database record"
                  />
                }
                {
                  data.pubmedId &&
                  <Button
                    target="_blank"
                    href={`https://pubmed.ncbi.nlm.nih.gov/${data.pubmedId}/`}
                    customClassName="button-light mr-5"
                    text="Go to Abstract"
                  />
                }
                {
                  showRightFind && data.pubmedId &&
                  <Button
                    target="_blank"
                    href={generateRightFindLink(data.pubmedId, rightFindUrlSuffix)}
                    customClassName="button-light mr-5"
                    text="Add to RightFind"
                  />
                }
                {
                  showReprintDesk && data.pubmedId &&
                  <Button
                    target="_blank"
                    href={generateReprintsDeskLink(data.pubmedId)}
                    customClassName="button-light mr-5"
                    text="Add to Article Galaxy"
                  />
                }
                <Button
                  customClassName="button-light"
                  onClick={() => { setSelectProjectModal(true); }}
                  text="Save to References"
                />
              </div>
              <div className="pub-details-article">
                <div className="pub-details__subtitle">Abstract</div>
                <PublicationDetailsAbstract
                  abstractText={getAbstractText()}
                  showTooltip={showTooltip}
                  hideTooltip={hideTooltip}
                  highlights={highlights.ABSTRACT}
                  prevHighlighs={prevHighlights.ABSTRACT}
                  semanticCategories={semanticCategories}
                  setShortConceptCardId={setShortConceptCardId}
                  searchTermsHighlighted={searchTermsHighlighted}
                />
              </div>
            </div>
          </div>
          <div className="pub-details__concepts pub-details-container">
            <PublicationDetailsConcepts
              conceptsData={conceptsData}
              changePage={changeConceptsPage}
              sorting={sorting}
              sort={sortConcepts}
              sliderValue={sliderValue}
              filteredConcepts={filteredConcepts}
              setSliderValue={setSliderValue}
              semanticCategories={semanticCategories}
            />
          </div>
        </div>
      }
      <Error show={error && !loading} error={error} />
      <Loader isLoading={loading} />
      <SelectProjectModal
        isOpen={selectProjectModal}
        closeCb={() => { setSelectProjectModal(false); }}
        onConfirm={handleSavingPublicationsToProject}
      />
    </div>
  );
};

PublicationDetailsInfo.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    data: SELECTORS.getPublicationDetailsDataSelector(state),
    loading: SELECTORS.getPublicationDetailsLoadingSelector(state),
    error: SELECTORS.getPublicationDetailsErrorSelector(state),
    sorting: SELECTORS.getConceptsSortingSelector(state),
    showRightFind: isRightFindAllowed(state),
    showReprintDesk: isReprintDeskAllowed(state),
    conceptsData: SELECTORS.getPublicationDetailsConceptsBaseSelector(state),
    sliderValue: SELECTORS.getSliderValueSelector(state),
    filteredConcepts: SELECTORS.getFilteredConceptsSelector(state),
    semanticCategories: SELECTORS.getSemanticCategories(state),
    highlights: SELECTORS.getHighlightsSelector(state),
    prevHighlights: SELECTORS.getPrevHighlightsSelector(state),
    allSelectedHighlights: SELECTORS.getAllSelectedHighlightsSelector(state),
    searchTermsHighlighted: SELECTORS.getSearchTermsHighlightedSelector(state),
    pubIdFromState: SELECTORS.getPublicationIdSelector(state),
    rightFindUrlSuffix: getRightFindUrlSuffix(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getPublicationDetailsData(id) {
      dispatch(ACTIONS.getPublicationDetailsDataAction(id));
    },
    getPublicationDetailsByPubMedIdData(id) {
      dispatch(ACTIONS.getPublicationDetailsByPubMedIdDataAction(id));
    },
    sortConcepts(data) {
      dispatch(ACTIONS.sortPublicationDetailsConceptsAction(data));
    },
    changeConceptsPage(data) {
      dispatch(ACTIONS.changeConceptsPageAction(data));
    },
    setSliderValue(data) {
      dispatch(ACTIONS.setSliderValueAction(data));
    },
    updateSemCategories(data) {
      dispatch(ACTIONS.updateSemCategoriesAction(data));
    },
    savePrevHighlights(data) {
      dispatch(ACTIONS.savePrevHighlightsAction(data));
    },
    toggleAllHighlights(data) {
      dispatch(ACTIONS.toggleAllHighlightsAction(data));
    },
    toggleSearchTermsHighlighted(data) {
      dispatch(ACTIONS.toggleSearchTermsHighlightedAction(data));
    },
    showTooltip(data) {
      dispatch(showTooltipAction(data));
    },
    hideTooltip() {
      dispatch(hideTooltipAction());
    },
    setShortConceptCardId(data) {
      dispatch(shortConceptCardSetIdAction(data));
    },
    savePublicationsToProject(data) {
      dispatch(savePublicationsToProjectAction(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PublicationDetailsInfo);
