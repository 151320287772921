import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

// Components
import Button from '../../../common/Buttons/Button/Button';
import SelectProjectModal from '../../../Modals/SelectProjectModal/SelectProjectModal';
import AddToRightfindWarningModal from '../../../Modals/AddToRightfindWarningModal/AddToRightfindWarningModal';
// Constants
import { THEME } from '../../../../constantsCommon';
// Utils
import { generateMultiRightFindLink } from '../../../Publications/PublicationsManagement/utils';
// Styles
import './index.scss';

const propTypes = {
  showRightFind: PropTypes.bool,
  rightFindUrlSuffix: PropTypes.string,
  selectedPubmedIds: PropTypes.instanceOf(Array),
  selectedPublicationsIds: PropTypes.instanceOf(Array),
  savePublicationsToProject: PropTypes.func,
  updateSelectedPublicationsIds: PropTypes.func,
};

const SearchPublicationsManage = (props) => {
  const {
    showRightFind,
    rightFindUrlSuffix,
    selectedPubmedIds,
    selectedPublicationsIds,
    savePublicationsToProject,
    updateSelectedPublicationsIds,
  } = props;
  const [showSelectProjectModal, setShowSelectProjectModal] = useState(false);
  const [showAddToRightfindWarningModal, setShowAddToRightfindWarningModal] = useState(false);

  function goRightFind() {
    window.open(generateMultiRightFindLink(selectedPubmedIds, rightFindUrlSuffix), '_blank');
  }

  const handleSavingPublicationsToProject = useCallback(() => {
    savePublicationsToProject({
      ids: selectedPublicationsIds,
    });
    updateSelectedPublicationsIds([]);
  }, [selectedPublicationsIds]);

  const handleAddToRightfind = useCallback(() => {
    if (selectedPublicationsIds.length !== selectedPubmedIds.length) {
      setShowAddToRightfindWarningModal(true);
    } else {
      goRightFind();
    }
  }, [selectedPublicationsIds, selectedPubmedIds]);

  return (
    <div className="search-publications-manage">
      <span className="search-page__subtitle">
        Manage References
      </span>
      <div className="search-publications-manage__wrap">
        {
          showRightFind &&
          <Button
            customClassName="search-publications-manage__btn"
            onClick={handleAddToRightfind}
            disabled={!selectedPubmedIds.length}
            text="Add to RightFind"
            theme={THEME.GREY}
          />
        }
        <Button
          customClassName="search-publications-manage__btn"
          onClick={() => setShowSelectProjectModal(true)}
          disabled={!selectedPublicationsIds.length}
          text="Save to References"
          theme={THEME.GREY}
        />
      </div>
      <SelectProjectModal
        isOpen={showSelectProjectModal}
        closeCb={() => setShowSelectProjectModal(false)}
        onConfirm={handleSavingPublicationsToProject}
      />
      <AddToRightfindWarningModal
        isOpen={showAddToRightfindWarningModal}
        closeCb={() => { setShowAddToRightfindWarningModal(false); }}
        onConfirm={goRightFind}
      />
    </div>
  );
};

SearchPublicationsManage.propTypes = propTypes;

export default SearchPublicationsManage;
