import { createSelector } from 'reselect';

import { sort } from '../../../Utils/Utils';
import {ProjectStatusOptions} from '../constants';

const projectForm = state => state.getIn(['form', 'PROJECT_FORM', 'values']);
const projects = state => state.getIn(['projectsManagementReducer', 'projects']);
const loading = state => state.getIn(['projectsManagementReducer', 'loading']);
const error = state => state.getIn(['projectsManagementReducer', 'error']);
const sorting = state => state.getIn(['projectsManagementReducer', 'sorting']);
const projectsNameFilter = state => state.getIn(['projectsManagementReducer', 'filters', 'projectName']);
const userNameFilter = state => state.getIn(['projectsManagementReducer', 'filters', 'userName']);
const projectData = state => state.getIn(['projectsManagementReducer', 'projectData']);
const projectUsers = state => state.getIn(['projectsManagementReducer', 'users']);

const showDeleteProjectPopup = state => state.getIn(['projectsManagementReducer', 'showDeleteProjectPopup']);
const showCreateProjectPopup = state => state.getIn(['projectsManagementReducer', 'showCreateProjectPopup']);
const showEditProjectPopup = state => state.getIn(['projectsManagementReducer', 'showEditProjectPopup']);
const popupError = state => state.getIn(['projectsManagementReducer', 'popupError']);

export const getProjectsManagementDataSelector = createSelector(
  projects,
  sorting,
  projectsNameFilter,
  (_projects, _sorting, _projectNameFilter) => {
    if (!_projects) return _projects;

    const sorted = sort(_projects, _sorting.toJS());
    const sortedProjects = sorted && sorted.toJS();
    let filteredProjects = sortedProjects;

    if (_projectNameFilter) {
      const filterValueLow = _projectNameFilter.toLowerCase();
      filteredProjects = filteredProjects.filter(p => (
        p.name.toLowerCase().includes(filterValueLow)
      ));
    }

    return filteredProjects;
  }
);

export const getProjectsManagementLoadingSelector = createSelector(
  loading,
  data => data
);

export const getProjectsManagementErrorSelector = createSelector(
  error,
  data => data
);

export const getProjectsSortingSelector = createSelector(
  sorting,
  data => data.toJS()
);

export const getProjectsNameFilterSelector = createSelector(
  projectsNameFilter,
  data => data
);

export const getProjectDataSelector = createSelector(
  projectData,
  data => data || { name: '', status: ProjectStatusOptions[0].type, description: '', users: []}
);

export const getProjectFormSelector = createSelector(
  projectForm,
  data => data && data.toJS()
);

export const getProjectUsersSelector = createSelector(
  projectUsers,
  userNameFilter,
  (data, filter) => {
    if (!data) return data;
    return data.toJS()
      .filter(user => user.fullName.toLowerCase().includes(filter.toLowerCase()))
      .sort((a, b) => a.fullName.localeCompare(b.fullName));
  }
);

export const showDeleteProjectPopupSelector = createSelector(
  showDeleteProjectPopup,
  data => data
);

export const showCreateProjectPopupSelector = createSelector(
  showCreateProjectPopup,
  data => data,
);

export const showEditProjectPopupSelector = createSelector(
  showEditProjectPopup,
  data => data,
);

export const getPopupErrorSelector = createSelector(
  popupError,
  data => data,
);

export const getUserNameFilterSelector = createSelector(
  userNameFilter,
  data => data
);
