import { takeLatest, call, put } from 'redux-saga/effects';

// Api
import Api from '../../../../Api/Api';
// Store
import * as a from './reducer';
// Constants
import { personalOption } from '../constants';

export function* getProjectsWorker() {
  try {
    yield put(a.toggleProjectsLoaderAction(true));
    const { data } = yield call(Api.getAllProjects);
    yield put(a.clearProjectsDataAction(data));
    yield put(a.putProjectsAction(data));
  } catch (e) {
    yield put(a.throwProjectsErrorAction(e.message));
    yield put(a.toggleProjectsLoaderAction(false));
  }
}

export function* getProjectsOptionsWorker() {
  try {
    yield put(a.toggleProjectsOptionsLoaderAction(true));
    const { data } = yield call(Api.getAllProjects, { editable: true });
    const options = data.reduce((o, d) => {
      o.push({
        label: d.name,
        type: d.name,
        value: d.name,
        id: d.id,
      });
      return o;
    }, [personalOption]);
    yield put(a.setProjectsOptionsAction(options));
  } catch (e) {
    yield put(a.throwProjectsOptionsErrorAction(e.message));
    yield put(a.toggleProjectsOptionsLoaderAction(false));
  }
}

function* projectsSaga() {
  yield takeLatest(a.getProjectsAction, getProjectsWorker);
  yield takeLatest(a.getProjectsOptionsAction, getProjectsOptionsWorker);
}

export default projectsSaga;
